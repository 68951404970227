<template>
  <div class="app-container none-border">
    <!-- <div class="content_select">
      <div>
        <el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel">
          <el-form class="styleForm">
            <el-form-item label="删除人员 ：" :label-width="formLabelWidth">
              <selectuser
                code="bookkeeping"
                v-model:userId="listQuery.userId"
                style="width: 235px;"
              ></selectuser>
            </el-form-item>
            <el-form-item label="金额区间 ：" :label-width="formLabelWidth">
              <el-input size="small" class="w-110" v-model="listQuery.startMoney" placeholder="金额起"></el-input>
              <span style="margin:0 4px">-</span>
              <el-input size="small" class="w-110" v-model="listQuery.endMoney" placeholder="金额止"></el-input>
            </el-form-item>
            <el-form-item label="删除日期 ：" :label-width="formLabelWidth">
              <el-date-picker
                style="width: 110px;"
                size="small"
                value-format="YYYY-MM-DD"
                v-model="listQuery.startDate"
                type="date"
                placeholder="删除日期起"
              >
              </el-date-picker>
              <span style="margin:0 4px">-</span>
              <el-date-picker
                style="width: 110px;"
                size="small"
                value-format="YYYY-MM-DD"
                v-model="listQuery.endDate"
                type="date"
                placeholder="删除日期止"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="摘要 ：" :label-width="formLabelWidth">
              <el-input size="small" class="w-234" v-model="listQuery.summary" placeholder="请输入摘要"></el-input>
            </el-form-item>
          </el-form>
        </search>
      </div>

      <div>
        <el-button type="primary" size="small" plain>恢复</el-button>
        <el-button type="danger" size="small">彻底删除</el-button>
      </div>
    </div> -->
    <div style="margin-top: 10px;">
      <el-table stripe :data="list" v-loading="loading" border :height="contentStyleObj">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <!-- <el-table-column align="center" type="selection" width="55" /> -->
        <el-table-column align="center" prop="period" label="账期" width="90">
        </el-table-column>
        <el-table-column align="center" prop="voucherNo" label="凭证字号" width="70">
          <template #default="scope">
            <span>
              <i class="iconfont icon-pingzheng" style="color:#39b0d2"></i>
              <span style="color:#39b0d2;font-size:13px;margin-left:5px;">{{
                scope.row.voucherNo }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="voucherDate" label="日期" width="100">
          <template #default="scope">
            <div class="voucher-date">
              <span>{{ $parseTime(scope.row.voucherDate, "{y}-{m}-{d}") }}</span>
              <el-button class="fold_icon" v-if="scope.row.voucherItem?.length > voucherItemsLimit"
                @click="scope.row.showAll = !scope.row.showAll" size="small">
                {{ !scope.row.showAll ? '展开全部' : '收起全部' }}
                <el-icon v-if="!scope.row.showAll">
                  <ArrowDown />
                </el-icon>
                <el-icon v-else>
                  <ArrowUp />
                </el-icon>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="摘要" width="250">
          <template #default="scope">
            <template v-for="(item, i) in scope.row.voucherItem">
              <p v-if="i < voucherItemsLimit || scope.row.showAll" :key="item.id">
                <el-tooltip effect="dark" :disabled="isShowTooltip" placement="top-start">
                  <template #content>
                    <div style="max-width: 300px;">
                      {{ item.summary }}
                    </div>
                  </template>
                  <span :ref="item.summary" @mouseover="onMouseOver(item.summary)">{{ item.summary }}</span>
                </el-tooltip>
              </p>
            </template>
          </template>
        </el-table-column>
        <el-table-column align="center" label="科目" width="250">
          <template #default="scope">
            <div>
              <template v-for="(item, i) in scope.row.voucherItem">
                <p class="detail-p" v-if="i < voucherItemsLimit || scope.row.showAll" :key="item.id">
                  <el-tooltip class="item" effect="dark" placement="top-start" :disabled="isShowTooltip" raw-content>
                    <template #content>
                      <div style="max-width: 500px;">
                        {{ item.fzhsName ? item.subjectName + '--辅助核算：' + item.fzhsName : item.subjectName }}
                      </div>
                    </template>
                    <span :ref="item.subjectName + item.fzhsName"
                      @mouseover="onMouseOver(item.subjectName + item.fzhsName)">{{
                        item.fzhsName ? item.subjectName + '--辅助核算：' + item.fzhsName : item.subjectName }}</span>
                  </el-tooltip>
                </p>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="借方">
          <template #default="scope">
            <template v-for="(item, i) in scope.row.voucherItem">
              <p v-if="i < voucherItemsLimit || scope.row.showAll" :key="item.id">
                {{ item.inAmount ? $comdify(item.inAmount) : "" }}
              </p>
            </template>
          </template>
        </el-table-column>
        <el-table-column align="center" label="贷方">
          <template #default="scope">
            <template v-for="(item, i) in scope.row.voucherItem">
              <p v-if="i < voucherItemsLimit || scope.row.showAll" :key="item.id">
                {{ item.outAmount ? $comdify(item.outAmount) : "" }}
              </p>
            </template>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="billCount" label="附件张数" width="100"></el-table-column>
        <el-table-column align="center" prop="fromType" label="凭证来源" width="100">
          <template #default="scope">
            <span>{{ $VoucherSourceFilter(scope.row.fromType) }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="createName" label="制单人" width="80">
          <template #default="scope">
            <span>{{ scope.row.createdBy == 1 ? '系统自动' : scope.row.createName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="checkName" label="审核人" width="80"></el-table-column>
        <el-table-column label="审核状态" align="center" width="80">
          <template #default="scope">
            <div v-if="scope.row.checkStatus == 0">
              <i class="iconfont icon-gantanhao" style="margin-right: 4px;font-size: 13px;"></i>未审核
            </div>
            <div v-else>
              <i class="iconfont icon-duihao" style="margin-right: 4px;font-size: 13px;"></i>已审核
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150">
          <template #default="scope">
            <el-button type="success" size="small" plain @click="updateVoucher(scope.row, '2')"
              :disabled="!$buttonStatus('pz_hfpz')">恢复</el-button>
            <el-button type="danger" size="small" plain @click="updateVoucher(scope.row, '1')"
              v-if="isAdmin == 1">彻底删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList()" />
    </div>
    <el-dialog :close-on-click-modal="false" v-model="dialogFormVisible" title="恢复凭证" width="450px" append-to-body>
      <el-form size="small" :model="form">
        <el-form-item label="恢复账期:" required>
          <el-radio-group v-model="form.periodType">
            <el-radio label="0">恢复到当期</el-radio>
            <el-radio label="1">恢复到凭证账期</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="createSure" :loading="btnLoadinng">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ElMessageBox, ElMessage } from 'element-plus'
import { voucherWareList, delectOrHfVoucher } from '@/api/voucher'
import { onMounted, ref, getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance()
const listQuery = ref({
  page: 1,
  limit: 20
})
const list = ref([])
const total = ref(0)
const loading = ref(false)
const contentStyleObj = ref({})
const voucherItemsLimit = 20
const isShowTooltip = ref(true)
const isAdmin = proxy.$store.getters["user/user"].isAdmin
const currentPeriod = proxy.$store.getters["user/comInfo"].period
const dialogFormVisible = ref(false)
const form = ref({})
const btnLoadinng = ref(false)
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210)
  getList()
})
const getList = () => {
  loading.value = true
  voucherWareList(listQuery.value).then(res => {
    loading.value = false
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : []
      total.value = res.data.data.total
    }
  })
}
const updateVoucher = (row, type) => {
  if (type == '2' && row.period != currentPeriod) {
    form.value = {
      voucherId: row.id,
      periodType: '0',
      types: type
    }
    dialogFormVisible.value = true
    return
  }
  ElMessageBox.confirm(`确定要${type == '1' ? '删除' : '恢复'}此凭证吗？`, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    let param = {
      voucherId: row.id,
      types: type
    }
    delectOrHfVoucher(param).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("操作成功");
        getList();
        proxy.$bus.emit("voucherUpdate");
      }
    });
  });
}
const createSure = () => {
  btnLoadinng.value = true
  delectOrHfVoucher(form.value).then((res) => {
    btnLoadinng.value = false
    if (res.data.msg == "success") {
      ElMessage.success("操作成功");
      dialogFormVisible.value = false
      getList();
      proxy.$bus.emit("voucherUpdate");
    }
  });
}
const onMouseOver = (str) => {
  let contentWidth = proxy.$refs[str].offsetWidth;
  isShowTooltip.value = contentWidth > 243 ? false : true
}
</script>

<style scoped lang="scss">
.content_select {
  margin: 15px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-table :deep(.cell) {
  overflow: unset
}

.none-border p {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #b9b9b9;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.none-border p:first-child {
  border-top: none;
}

.fold_icon {
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 1;
  padding: 0 10px;
  border-radius: 14px;
  font-size: 11px !important;
  height: 20px;
  line-height: 20px;
}
</style>
<style lang="scss" scoped></style>
<style>
.none-border .cell {
  padding: 0;
}

.none-border .el-table__row td {
  padding: 0 !important;
}

.none-border .cell {
  padding: 0;
  text-align: center;
}
</style>